module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Odkryj druk 3D w stomatologii z SprintRay | 3D Phoenix Dental","short_name":"SprintRay 3D Phoenix Dental","description":"Odkryj innowacyjny amerykański system druku 3D dla stomatologii. Poznaj zaawansowane żywice ceramiczne idealne do tworzenia koron i mostów. Przenieś swoją praktykę dentystyczną na wyższy poziom dzięki nowoczesnym rozwiązaniom SprintRay w stomatologii cyfrowej.","start_url":"/","lang":"pl","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"src/images/maskable_icon.png","cache_busting_mode":"none","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
